import "./assets/styles/styles.scss";

// Set copyright year
const setCopyright = () => {
  const footerElement = document.querySelector("footer");
  const copyrightYear = new Date().getFullYear();
  footerElement.querySelector("span").innerHTML = copyrightYear;
};

// Executes
setCopyright();
